import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Common from '../../constants/common';
import { Emitter_Keys } from '../../constants/event_emitter_keys';
import ImagesBase64 from '../../constants/images_base64';
import { emitter } from '../../helpers/emitter';

export interface ErrorProps {
  isError?: boolean;
  description?: string;
  title?: string;
  buttonText?: string;
  image?: string;
  onButtonHandler?: Function;
}

interface IGlobalAppState {
  globalMessage: string;
  isGlobalLoading: boolean;
  isPartnerLoading: boolean;
  globalError: ErrorProps;
}

const initialState: IGlobalAppState = {
  globalMessage: '',
  globalError: {
    isError: false,
    buttonText: 'Kembali',
    image: ImagesBase64.genericError,
    description: Common.errorGenericDescription,
    title: Common.errorGenericTitle,
  },
  isGlobalLoading: false,
  isPartnerLoading: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setGlobalMessage: (state, action: PayloadAction<string>) => {
      state.globalMessage = action.payload;
    },
    setGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.isGlobalLoading = action.payload;
    },
    setPartnerLoading: (state, action: PayloadAction<boolean>) => {
      state.isPartnerLoading = action.payload;
    },
    setGlobalError: (state, action: PayloadAction<ErrorProps>) => {
      const globalError: ErrorProps = {
        ...state.globalError,
        ...action.payload,
      };

      state.globalError = globalError;
      emitter.emit(Emitter_Keys.SHOW_GLOBAL_ERROR, globalError);
    },
    setToGlobalErrorInitial: (state) => {
      state.globalError = initialState.globalError;
    },
  },
});

export const {
  setGlobalMessage,
  setGlobalError,
  setGlobalLoading,
  setPartnerLoading,
  setToGlobalErrorInitial,
} = appSlice.actions;
export default appSlice.reducer;
