import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import Common from '../constants/common';
import { setGlobalError } from '../redux/slices/appSlice';
import { UseMethodTransferData } from 'application/hooks/useMethodList';

export const Utils = {
  rupiahWithoutDecimal: (number: number): string => {
    return new Intl.NumberFormat('id-ID', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(Math.floor(number));
  },

  balanceDecimal: (number: number): string => {
    return (number % 1).toFixed(2).substring(2);
  },

  toTitleCase: (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  },

  delay: (ms?: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  getFirstDayOfMonth: (year: number, month: number) => {
    const date = new Date(year, month, 1);
    return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${
      date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    }/${date.getFullYear()}`;
  },

  getLastDayOfMonth: (year: number, month: number) => {
    const date = new Date(year, month + 1, 0);
    return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${
      date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    }/${date.getFullYear()}`;
  },

  convertToFormatID: (date: string, shortMonth?: boolean) => {
    const stringDate = date.split('-');
    return `${stringDate[2]} ${
      shortMonth
        ? Common.shortMonths[Number(stringDate[1]) - 1]
        : Common.months[Number(stringDate[1]) - 1]
    } ${stringDate[0]}`;
  },

  generateArrayOfYears: () => {
    const max = process.env.APP_CONFIG_ENV === 'development' ? 2050 : new Date().getFullYear();
    const min = max - (max - 1);
    const years = [];

    for (var i = max; i >= min; i--) {
      years.push(i + '');
    }
    return years;
  },

  convertStringToMonth: (value: string) => {
    return Common.months.indexOf(value);
  },

  idrFormatted: (number: number | bigint) => {
    return new Intl.NumberFormat('id-ID').format(number);
  },

  accountNumberFormatter: (value: string) => {
    return value?.replace(/(\d{4})(?=\d)/g, '$1 ');
  },

  padLeft: (str: string, targetLength: number, padString: string) => {
    return str.padStart(targetLength, padString);
  },

  accountNumberUnformatted: (str: string) => {
    const clearValue = str.replaceAll(' ', '');

    return clearValue;
  },

  convertTime: (str: string) => {
    const value = str.split(':');
    return `${value[0]}:${value[1]}`;
  },

  currentDateFormatted: (shortMonth: boolean = false) => {
    const current = new Date();
    const getMonth = () => {
      if (shortMonth) return `${Common.shortMonths[current.getMonth()]}`;

      return `${Common.months[current.getMonth()]}`;
    };

    return `${current.getDate()} ${getMonth()} ${current.getFullYear()}`;
  },

  currentFullTime: () => {
    const current = new Date();
    return `${Utils.padLeft(current.getHours().toString(), 2, '0')}:${Utils.padLeft(
      current.getMinutes().toString(),
      2,
      '0'
    )}:${Utils.padLeft(current.getSeconds().toString(), 2, '0')}`;
  },

  currentDateYyyymmdd: () => {
    const current = new Date();
    return `${Utils.padLeft(current.getFullYear().toString(), 4, '0')}-${Utils.padLeft(
      (current.getMonth() + 1).toString(),
      2,
      '0'
    )}-${Utils.padLeft(current.getDate().toString(), 2, '0')}`;
  },

  prepareMaskedAccountNumber: (value: string): string => {
    const length = value.length;
    if (length >= 10) {
      var maskedNumber: string = '';

      const lastNCharacters: number = length - 4;

      var twoDigits: string = '';

      for (var i = 0; i < lastNCharacters; i++) {
        twoDigits += '*';
      }

      var lastThreeDigits: string = value.substring(lastNCharacters);

      maskedNumber = `${twoDigits}${lastThreeDigits}`;

      return maskedNumber.toString();
    }

    return value;
  },

  spaceAfterWords: (value: string, count: number): string => {
    if (value.length == 0) {
      return value;
    }

    var buffer: string = '';
    for (let i = 0; i < value.length; i++) {
      buffer += value[i];
      const nonZeroIndex = i + 1;
      if (nonZeroIndex % count == 0 && nonZeroIndex != value.length) {
        buffer += ' ';
      }
    }

    return buffer.toString();
  },

  showGenericErrorWithFormat: (error: any, dispatch: Dispatch<AnyAction>) => {
    if (!error.alreadyShowPopup) {
      dispatch(
        setGlobalError({
          isError: true,
          description: `${Common.errorGenericDescription} (${error.response?.data.errors})`,
        })
      );
    }
  },

  typeOfTransactionForShareAndDownload: (method: string): string => {
    switch (method.toUpperCase()) {
      case 'TRANSFER ONLINE':
      case 'INTERNAL':
        return 'ONLINE';
      case 'BIFAST':
        return 'BI FAST';
      case 'BI FAST':
        return 'BI FAST';
      case 'BI-FAST':
        return 'BI FAST';
      case 'SKN':
        return 'SKN';
      case 'RTGS':
        return 'RTGS';
      case 'QRIS':
        return 'QRIS';
      case 'ONLINE_BUSINESS':
        return 'Transfer Online';
      case 'BIFAST_BUSINESS':
        return 'BI-FAST';
      case 'INTERNAL_BUSINESS':
        return 'Online';
      default:
        return '';
    }
  },

  formatScheduleFrequencyDescription: (frequency: string, date?: number, day?: string): string => {
    switch (frequency) {
      case 'monthly':
        return `Bulanan, Setiap Tanggal ${date}`;
      case 'daily':
        return `Setiap Hari`;
      case 'weekly':
        return `Mingguan, Setiap ${day}`;
      default:
        return frequency;
    }
  },

  randomColor: (index: number) => {
    const randomColors = [
      'bg-blue-raya-50',
      'bg-orange-raya-50',
      'bg-bright-cerulean-50',
      'bg-message-green-60',
      'bg-purple-60',
    ];

    return randomColors[index % randomColors.length];
  },

  formatTime(dateTimeString: any) {
    const date = new Date(dateTimeString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}.${minutes}`;
  },

  convertIso8601: (date: string) => {
    const dateObj = new Date(date);

    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'Asia/Jakarta',
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Asia/Jakarta',
      timeZoneName: 'short',
    };

    const formattedDate = dateObj.toLocaleDateString('id-ID', optionsDate);
    const formattedTime = dateObj.toLocaleTimeString('id-ID', optionsTime);

    return `${formattedDate} • ${formattedTime}`;
  },

  convertIso8601WithoutTime: (date: string) => {
    const dateObj = new Date(date);

    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'Asia/Jakarta',
    };

    const formattedDate = dateObj.toLocaleDateString('id-ID', optionsDate);

    return `${formattedDate}`;
  },

  getTodayDateAndDay: () => {
    const today = new Date();
    const dayIndonesia = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    const todayDate = today.getDate();
    const todayDay = dayIndonesia[today.getDay()];

    return { todayDay, todayDate };
  },

  checkStatusBiFastFromMethod: (methodData: UseMethodTransferData[] | undefined) => {
    let isBiFastUnderMaintenance: boolean | undefined = false;
    let biFastMethod = undefined;

    if (!methodData || methodData.length === 0) {
      return { isBiFastUnderMaintenance, biFastMethod: undefined };
    }

    biFastMethod = methodData.find(({ name }) => name?.toUpperCase() === 'BI-FAST');

    isBiFastUnderMaintenance = biFastMethod && biFastMethod.status?.toUpperCase() === 'MAINTENANCE';

    return { isBiFastUnderMaintenance, biFastMethod };
  },
};
