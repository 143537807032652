import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Common from 'application/constants/common';
import { Utils } from 'application/helpers/utils';
import { ActiveFilter } from 'application/models/active_filter';

export interface ISakuState {
  historyFilter: ActiveFilter | null;
}

const currentDate = new Date();

const initialState = {
  historyFilter: {
    isActiveFilter: false,
    dateFilter: {
      months: Common.months[currentDate.getMonth()],
      years: `${currentDate.getFullYear()}`,
    },
    dateRangeFilter: {
      startDate: Utils.getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth()),
      endDate: Utils.getLastDayOfMonth(currentDate.getFullYear(), currentDate.getMonth()),
    },
    optionFilter: Common.FILTER_OPTIONS[0],
  },
} as ISakuState;

const sakuSlice = createSlice({
  name: 'saku',
  initialState,
  reducers: {
    setHistoryFilter: (state, action: PayloadAction<ActiveFilter>) => {
      state.historyFilter = {
        ...state.historyFilter,
        ...action.payload,
      };
    },
    removeHistoryFilter: (state) => {
      state.historyFilter = initialState.historyFilter;
    },
  },
});

export const { setHistoryFilter, removeHistoryFilter } = sakuSlice.actions;
export default sakuSlice.reducer;
